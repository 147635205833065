#profileChangeButton, #pwdChangeButton  {
    /*width: 76px;*/
    /*height: 22.5px;*/
    /*margin: 14.5px 162px 0 0.5px;*/
    padding: 6px 23px 6.5px;
    border-radius: 100px;
    background-color: #af40c9;
}

.settings-page-button {
    padding: 6px 23px 6.5px;
    border-radius: 100px;
    background-color: #af40c9;
}

label {
    font-family: 'Raleway';
    color: #5a5a5a;
}
.settings-label {
    /*width: 22px;*/
    /*height: 8px;*/
    /*margin: 15px 47.5px 6px 0;*/
    /*font-family: Raleway;*/
    /*font-size: 7px;*/
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #161616;
}

.setting-subtitle {
    /*width: 73px;*/
    /*height: 10px;*/
    /*margin: 0 112.9px 6px 0;*/
    /*font-family: 'Raleway';*/
    font-size: 8.5px;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #5a5a5a;
}

